import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Select from "react-select";

import { ViewVarients } from "../../../Api Services/adminHttpServices/dashHttpServices";
import Header from "../../Header";
import SidebarAdmin from "../../SidebarAdmin";

const ViewVariant = ({ itemId, onVariantAdded }) => {
  const [viewData, setViewData] = useState(null);
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const { state } = useLocation();
  // const { t, i18n } = useTranslation();

  useEffect(() => {
    getViewVarient();
  }, []);

  const getViewVarient = async () => {
    try {
      const { data } = await ViewVarients(state);
      const variantData = data?.results?.varient?.[0];
      setViewData(variantData);
      const attributes =
        variantData?.attributesValues?.map((attr) => ({
          value: attr?.attribute._id,
          label: attr?.attribute.attributeName_en,
        })) || [];
      setSelectedAttributes(attributes);

      const values =
        variantData?.attributesValues?.flatMap((attrValue) =>
          attrValue?.value?.map((val) => ({
            value: val?._id,
            label: val?.valuesName_en,
            attributes: attrValue?.attribute?._id,
          }))
        ) || [];
      setSelectedValues(values);
    } catch (error) {
      console.error("Error fetching variant view:", error);
    }
  };
  console.log(selectedValues);

  return (
    <div>
      <div className="tap-top">
        <span className="lnr lnr-chevron-up" />
      </div>
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
        <Header />
        <div className="page-body-wrapper">
          <SidebarAdmin slide={"Item Management"} />
          <div className="page-body">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <div>
                        <div className="title-header option-title">
                          {/* <h5>{t("View Variant")}</h5> */}
                          <h5>View Variant</h5>
                        </div>
                        <form
                          className="theme-form theme-form-2 mega-form"
                          //   onSubmit={handleSubmit(onSubmit)}
                        >
                          <div className="col-md-12 mb-4 mt-4">
                            {viewData?.image?.length > 0 &&
                              viewData?.image.map((img, index) => (
                                <img
                                  key={`existing-${index}`}
                                  src={img}
                                  alt={`Existing image ${index}`}
                                  className="me-1 img-fluid"
                                  style={{
                                    cursor: "pointer",
                                    width: "70px",
                                    height: "70px",
                                    objectFit: "contain",
                                  }}
                                />
                              ))}
                          </div>
                          <div className="row">
                            <div className="col-md-6 mb-4 mt-4">
                              <label className="form-label-title mb-2">
                                {/* {t("Selected Attributes")} */}
                                Selected Attributes
                              </label>
                              <Select
                                // options={selectedAttributes}
                                isMulti
                                // onChange={handleAttributeChange}
                                value={selectedAttributes}
                                isDisabled
                              />
                            </div>
                            <div className="col-md-6 mb-4 mt-4">
                              <label className="form-label-title mb-2">
                                Selected Values
                                {/* {t("Selected Values")} */}
                              </label>
                              <Select
                                // options={selectedValues}
                                isMulti
                                // onChange={handleValueChange}
                                value={selectedValues}
                                isDisabled
                              />
                            </div>
                            <div className="col-md-4 mb-4 mt-4">
                              <label className="form-label-title mb-2">
                                {/* {t("Quantity")} */}
                                Quantity
                              </label>
                              <input
                                className="form-control"
                                type="number"
                                value={viewData?.quantity}
                              />
                            </div>
                            <div className="col-md-4 mb-4 mt-4">
                              <label className="form-label-title mb-2">
                                {/* {t("Price")} */}
                                Price
                              </label>
                              <input
                                className="form-control"
                                name="Price"
                                type="number"
                                value={viewData?.Price}
                              />
                            </div>
                            <div className="col-md-4 mb-5 mt-4">
                              <label className="form-label-title mb-2">
                                {/* {t("Discounted Price")} */}
                                Discounted Price
                              </label>
                              <input
                                className="form-control"
                                name="discountPrice"
                                type="number"
                                value={viewData?.discountPrice}
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
           {/* <div className="container-fluid">
            <footer className="footer">
              <div className="row">
                <div className="col-md-12 footer-copyright text-center">
                  <p className="mb-0">Copyright 2024</p>
                </div>
              </div>
            </footer>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ViewVariant;
