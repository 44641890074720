import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import Swal from "sweetalert2";
import { userDataState } from "../Recoil/userData";
import { sidebarMenuOptions } from "./sidebarMenuOptions";

const ProtectedRoutes = ({ children }) => {
  const token = localStorage.getItem("boutique-token-admin");
  const location = useLocation();
  const getUserProfileDataState = useRecoilValue(userDataState);
  const userAccess = getUserProfileDataState?.access;

  if (!token || token?.split(".")?.length <= 2) {
    Swal.fire({
      icon: "warning",
      title: "Invalid Token",
      text: "Please login to visit this page",
      showConfirmButton: true,
      timer: false,
      confirmButtonColor: "rgb(4 98 176)",
    });
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  const matchedSidebarOption = sidebarMenuOptions?.find((option) =>
    location.pathname?.startsWith(option?.link)
  );
  if (
    matchedSidebarOption &&
    !userAccess?.includes(matchedSidebarOption.role)
  ) {
    Swal.fire({
      icon: "warning",
      title: "Access Denied",
      text: "You do not have permission to access this page.",
      showConfirmButton: true,
      timer: false,
      confirmButtonColor: "rgb(4 98 176)",
    });
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  if (
    token &&
    getUserProfileDataState?.type === "subAdmin" &&
    location?.pathname.startsWith("/sub-admin-management")
  ) {
    Swal.fire({
      icon: "warning",
      title: "Access Denied",
      text: "You are not allowed to access this page",
      showConfirmButton: true,
      timer: false,
      confirmButtonColor: "rgb(4 98 176)",
    });
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return children;
};

export default ProtectedRoutes;
