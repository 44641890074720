import React from "react";
import SidebarAdmin from "../SidebarAdmin";
import Header from "../Header";
import { useLocation } from "react-router-dom";
import moment from "moment";

const ViewNotification = () => {
  const { state } = useLocation();
  return (
    <div>
      <div>
        {" "}
        <div className="tap-top">
          <span className="lnr lnr-chevron-up" />
        </div>
        {/* tap on tap end */}
        {/* page-wrapper Start*/}
        <div className="page-wrapper compact-wrapper" id="pageWrapper">
          <Header />
          {/* Page Body Start*/}
          <div className="page-body-wrapper">
            {/* Page Sidebar Start*/}
            <SidebarAdmin slide={"Notifications"} />
            {/* Page Sidebar Ends*/}
            {/* index body start */}
            <div className="page-body">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="title-header option-title">
                          <h5>View Notification Details</h5>
                        </div>
                        <div className="row mt-5">
                          <div className="col-md-6 mb-4">
                            <label className="form-label-title mb-2">
                              Title
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={state?.title_en}
                              disabled
                            />
                          </div>
                          <div className="col-md-6 mb-4">
                            <label className="form-label-title mb-2">
                              Date & Time
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={moment(state?.createdAt).format(
                                "MMM Do YYYY : HH:mm A"
                              )}
                              disabled
                            />
                          </div>
                          <div className="col-md-12 mb-4">
                            <label className="form-label-title mb-2">
                              Description
                            </label>
                            <textarea
                              type="text"
                              className="form-control"
                              value={state.description_en}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewNotification;
