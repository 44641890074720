import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { navState } from "../Recoil/handleSidebar";
import { userDataState } from "../Recoil/userData";
import Swal from "sweetalert2";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const navCrrState = useRecoilValue(navState);
  const setNavState = useSetRecoilState(navState);
  const getUserProfileDataState = useRecoilValue(userDataState);

  console.log(getUserProfileDataState)

  const handleLogout = async () => {
    localStorage.removeItem("boutique-token-admin");
    localStorage.removeItem("boutique_admin_id");
    navigate("/login");
  };

  return (
    <div className="page-header close_icon">
      <div className="header-wrapper m-0">
        <div className="header-logo-wrapper p-0">
          {navCrrState && (
            <div
              className="sidebar_icon2 mb-0 pb-0 d-flex justify-content-center ms-4 ps-1 fs-5 d-lg-none"
              onClick={() => setNavState(!navCrrState)}
            >
              <Link to={"/dashboard"} className="d-none d-sm-inline">
                {getUserProfileDataState?.type === "admin"
                  ? "Boutique Admin"
                  : "Boutique Sub Admin"}
              </Link>
              <i class="fa fa-angle-left"></i>
            </div>
          )}
        </div>
        <div className="nav-right col-6 pull-right right-header p-0">
          <ul className="nav-menus">
            <li className="profile-nav onhover-dropdown pe-0 me-0">
              <div className="media profile-media">
                <img
                  className="user-profile rounded-circle"
                  src={"/images/admin logo.png"}
                  // src={getUserProfileDataState?.image || "/images/4.jpg"}
                  alt="profile"
                />
                <div className="user-name-hide media-body">
                  <span>{getUserProfileDataState?.name}</span>
                  <p className="mb-0 font-roboto">
                    {getUserProfileDataState?.type === "admin"
                      ? "Admin"
                      : "Sub Admin"}{" "}
                    <i className="middle ri-arrow-down-s-line" />
                  </p>
                </div>
              </div>
              <ul className="profile-dropdown onhover-show-div">
                <li>
                  <a href="/admin-profile">
                    <i className="fas fa-user" />
                    <span>My Profile</span>
                  </a>
                </li>
                {getUserProfileDataState?.type === "admin" && (
                  <li>
                    <Link to="/change-password">
                      <i className="fas fa-sync" />
                      <span>Change Password</span>
                    </Link>
                  </li>
                )}

                <li onClick={handleLogout}>
                  <a>
                    <i className="fas fa-sign-out-alt" />
                    <span>Logout</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
