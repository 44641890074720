import React, { useEffect, useState } from "react";
import Header from "../Header";
import SidebarAdmin from "../SidebarAdmin";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import moment from "moment";
import { SubscriptionList } from "../../Api Services/adminHttpServices/dashHttpServices";

const Subscription = () => {
  const [dataTable, setDataTable] = useState({
    columns: [
      {
        label: "S.No.",
        field: "sn",
        width: 50,
        selected: false,
      },
      {
        label: "Name",
        field: "name",
        width: 50,
        selected: false,
      },
      {
        label: "Cost",
        field: "cost",
        width: 50,
        selected: false,
      },
      {
        label: "Plan Type",
        field: "type",
        width: 50,
        selected: false,
      },
      {
        label: "Validity",
        field: "validity",
        width: 50,
        selected: false,
      },

      {
        label: "Action",
        field: "actions",
        width: 100,
        selected: false,
      },
    ],
    rows: [],
  });

  const [filterData, setFilterData] = useState({
    search: "",
    from: "",
    to: "",
  });

  useEffect(() => {
    const controller = new AbortController();
    let signal = controller.signal;
    getSubscription(signal);

    return () => {
      controller.abort();
    };
  }, [filterData]);

  const getSubscription = async (signal) => {
    let { data } = await SubscriptionList(filterData, { signal });
    const newRows = [];
    if (!data?.error) {
      let values = data?.results?.subcription;
      console.log(values);
      values?.sort((a, b) => new Date(b?.updatedAt) - new Date(a?.updatedAt));
      values?.map((data, i) => {
        let returnData = {};
        returnData.sn = i + 1;
        returnData.name = data?.merchantId?.name || "NA";
        returnData.cost = data?.cost || "NA";
        returnData.type = data?.planType || "NA";
        returnData.validity = moment(data?.validity).format(
          "MMM Do YYYY : HH:mm A"
        );

        returnData.actions = (
          <ul className="d-flex justify-content-center">
            <li>
              <Link
                className="mx-2"
                to="/subscription-plan-management/view"
                state={data}
              >
                <i className="ri-eye-line" />
              </Link>
            </li>
            {/* <li>
              <a
                onClick={() => handleItemDelete(data?._id)}
                className="cursor_pointer"
                alt="i"
                type="button"
              >
                <i className="ri-delete-bin-line" />
              </a>
            </li> */}
          </ul>
        );
        newRows.push(returnData);
      });
      setDataTable({ ...dataTable, rows: newRows });
    }
  };
  const handleReset = () => {
    document.getElementById("resetForm").click();
    setFilterData({
      search: "",
      from: "",
      to: "",
    });
  };

  // const handleItemDelete = async (id) => {
  //   const confirmResult = await Swal.fire({
  //     title: "Are you sure?",
  //     text: "You want to delete item",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, delete it!",
  //   });
  //   if (confirmResult.isConfirmed) {
  //     try {
  //       let { data } = await DeleteNotification(id);
  //       if (data && !data?.error) {
  //         Swal.fire({
  //           toast: true,
  //           icon: "success",
  //           position: "top-end",
  //           title: "Notification Deleted",
  //           showConfirmButton: false,
  //           timerProgressBar: true,
  //           timer: 3000,
  //         });
  //         getSubscription();
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  // };
  return (
    <div>
      <div>
        <div className="tap-top">
          <span className="lnr lnr-chevron-up" />
        </div>
        {/* tap on tap end */}
        {/* page-wrapper Start*/}
        <div className="page-wrapper compact-wrapper" id="pageWrapper">
          <Header />
          {/* Page Body Start*/}
          <div className="page-body-wrapper">
            {/* Page Sidebar Start*/}
            <SidebarAdmin slide={"Subscription Management"} />
            {/* Page Sidebar Ends*/}
            {/* index body start */}
            <div className="page-body">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card card-table">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <div className="title-header option-title d-sm-flex d-block">
                              <h5>Subscription Plan Management</h5>
                            </div>
                          </div>
                          <div className="col-auto d-flex align-items-center">
                            <form className="table_searcch" action>
                              <div className="d-flex align-items-center">
                                <label className="mb-0 me-3" htmlFor>
                                  Search:
                                </label>
                                <input
                                  value={filterData?.search}
                                  onChange={(e) => {
                                    setFilterData({
                                      ...filterData,
                                      search: e.target.value,
                                    });
                                  }}
                                  type="text"
                                  className="form-control"
                                />
                              </div>
                            </form>
                            <a
                              className="filter_btn"
                              data-bs-toggle="modal"
                              data-bs-target="#filter"
                              href="javascript:;"
                            >
                              <i className="fas fa-filter" />
                            </a>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 mdb_table mt-3 ">
                            <div className="table-responsive">
                              <MDBDataTable
                                bordered
                                // hover
                                striped
                                displayEntries={false}
                                entries={10}
                                className="text-nowrap border-none"
                                data={dataTable}
                                noBottomColumns
                                paginationLabel={"«»"}
                                sortable={false}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade filter_modal"
              id="filter"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex={-1}
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body">
                    <h5 className="modal-title" id="staticBackdropLabel">
                      Filter
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                    <form className="theme-form theme-form-2 mega-form px-4">
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label className="form-label-title mb-2">From</label>
                          <input
                            className="form-control"
                            // onChange={(e) => setStartDate(e.target.value)}
                            onChange={(e) =>
                              setFilterData({
                                ...filterData,
                                from: e.target.value,
                              })
                            }
                            type="date"
                            placeholder
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label-title mb-2">To</label>
                          <input
                            className="form-control"
                            // onChange={(e) => setEndDate(e.target.value)}
                            onChange={(e) =>
                              setFilterData({
                                ...filterData,
                                to: e.target.value,
                              })
                            }
                            type="date"
                            placeholder
                          />
                        </div>
                      </div>
                      <button className="d-none" type="reset" id="resetForm">
                        reset
                      </button>
                    </form>
                    <div className="button-box mt-3">
                      <button
                        type="reset"
                        className="btn btn--no"
                        data-bs-dismiss="modal"
                        onClick={handleReset}
                      >
                        Reset
                      </button>
                      <button
                        data-bs-dismiss="modal"
                        type="button"
                        className="btn btn--yes btn-primary"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* footer start*/}
            {/* <div className="container-fluid">
              <footer className="footer">
                <div className="row">
                  <div className="col-md-12 footer-copyright text-center">
                    <p className="mb-0">Copyright 2024</p>
                  </div>
                </div>
              </footer>
            </div> */}
            {/* footer End*/}
          </div>
          {/* index body end */}
        </div>
        {/* Page Body End */}
      </div>
      {/* page-wrapper End*/}
      {/* Modal Start */}
    </div>
  );
};

export default Subscription;
