import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loader from "./Boutique Admin/Loader.jsx";
import ViewNotification from "./Boutique Admin/Notification/ViewNotification.jsx";
import ViewVariant from "./Boutique Admin/ItemManagement/Varient/ViewVariant.jsx";
import Subscription from "./Boutique Admin/Subs/Subscription.jsx";
import View from "./Boutique Admin/Subs/View.jsx";
import ProtectedRoutes from "./utils/ProtectedRoutes.jsx";

const DashboardAdmin = lazy(() =>
  import("./Boutique Admin/Dashboard/DashboardAdmin.jsx")
);
const Business = lazy(() => import("./Boutique Admin/Merchant/Business.jsx"));
const BillingManagementAdmin = lazy(() =>
  import("./Boutique Admin/BillingManagementAdmin.jsx")
);
const BillingDetailsAdmin = lazy(() =>
  import("./Boutique Admin/BillingDetailsAdmin.jsx")
);
const NotificationsAdmin = lazy(() =>
  import("./Boutique Admin/Notification/NotificationsAdmin.jsx")
);
const NotificationsSettings = lazy(() =>
  import("./Boutique Admin/Notification/Settings.jsx")
);
const TransactionManagementAdmin = lazy(() =>
  import("./Boutique Admin/TransactionManagementAdmin.jsx")
);
const Profile = lazy(() => import("./Boutique Admin/Profile.jsx"));
const ChangePassword = lazy(() =>
  import("./Boutique Admin/ChangePassword.jsx")
);
const ForgotPassword = lazy(() =>
  import("./Boutique Admin/ForgotPassword.jsx")
);
const ResetPassword = lazy(() => import("./Boutique Admin/ResetPassword.jsx"));
const Verification = lazy(() => import("./Boutique Admin/Verification.jsx"));
const MerchantsDetails = lazy(() =>
  import("./Boutique Admin/Merchant/MerchantsDetails.jsx")
);
const MerchantsManagement = lazy(() =>
  import("./Boutique Admin/Merchant/MerchantsManagement.jsx")
);
const MerchantsEdit = lazy(() =>
  import("./Boutique Admin/Merchant/MerchantsEdit.jsx")
);
const ItemManagement = lazy(() =>
  import("./Boutique Admin/ItemManagement/ItemManagement.jsx")
);
const ItemEdit = lazy(() =>
  import("./Boutique Admin/ItemManagement/ItemEdit.jsx")
);
// const ItemDetails = lazy(() =>
//   import("./Boutique Admin/ItemManagement/ItemDetails.jsx")
// );
const SubAdminManagement = lazy(() =>
  import("./Boutique Admin/SubAdmin/SubAdminManagement.jsx")
);
const EditSubadmin = lazy(() =>
  import("./Boutique Admin/SubAdmin/EditSubadmin.jsx")
);
const ViewSubadmin = lazy(() =>
  import("./Boutique Admin/SubAdmin/ViewSubadmin.jsx")
);
const UserManagament = lazy(() =>
  import("./Boutique Admin/UserManagement/UserManagament.jsx")
);
const UserDetails = lazy(() =>
  import("./Boutique Admin/UserManagement/UserDetails.jsx")
);
const CategoryManagementAdmin = lazy(() =>
  import("./Boutique Admin/Category/CategoryManagementAdmin.jsx")
);
const CategoryEdit = lazy(() =>
  import("./Boutique Admin/Category/CategoryEdit.jsx")
);
const EditSubCategory = lazy(() =>
  import("./Boutique Admin/Category/EditSubCategory.jsx")
);
const AddNewPromoAdmin = lazy(() =>
  import("./Boutique Admin/PromoCode/AddNewPromoAdmin.jsx")
);
const PromoDetailsAdmin = lazy(() =>
  import("./Boutique Admin/PromoCode/PromoDetailsAdmin.jsx")
);
const PromotionCodeManagement = lazy(() =>
  import("./Boutique Admin/PromoCode/PromotionCodeManagement.jsx")
);
const OrderDetails = lazy(() =>
  import("./Boutique Admin/OrderManagement/OrderDetails.jsx")
);
const OrderManagementAdmin = lazy(() =>
  import("./Boutique Admin/OrderManagement/OrderManagementAdmin.jsx")
);

const NewSubAdmin = lazy(() =>
  import("./Boutique Admin/SubAdmin/NewSubAdmin.jsx")
);
const PushNotification = lazy(() =>
  import("./Boutique Admin/PushNotification/PushNotification.jsx")
);
const AddPushNotification = lazy(() =>
  import("./Boutique Admin/PushNotification/AddPushNotification.jsx")
);
const PushNotificationDetails = lazy(() =>
  import("./Boutique Admin/PushNotification/PushNotificationDetails.jsx")
);
const EditContentAdmin = lazy(() =>
  import("./Boutique Admin/ContentManagement/EditContentAdmin.jsx")
);
const ContentManagement = lazy(() =>
  import("./Boutique Admin/ContentManagement/ContentManagement.jsx")
);
const HelpandSupportAdmin = lazy(() =>
  import("./Boutique Admin/Help/HelpandSupportAdmin.jsx")
);
const HelpDetailsAdmin = lazy(() =>
  import("./Boutique Admin/Help/HelpDetailsAdmin.jsx")
);

const Login = lazy(() => import("./Boutique Admin/Login.jsx"));

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="*"
          element={
            <Suspense fallback={<Loader />}>
              <Login />
            </Suspense>
          }
        />
        <Route
          path="/"
          element={
            <Suspense fallback={<Loader />}>
              <Login />
            </Suspense>
          }
        />

        <Route
          exact
          path="/admin-profile"
          element={
            <Suspense fallback={<Loader />}>
              <Profile />
            </Suspense>
          }
        />
        <Route
          exact
          path="/change-password"
          element={
            <Suspense fallback={<Loader />}>
              <ChangePassword />
            </Suspense>
          }
        />
        <Route
          exact
          path="/login"
          element={
            <Suspense fallback={<Loader />}>
              <Login />
            </Suspense>
          }
        />
        <Route
          exact
          path="/forgot-password"
          element={
            <Suspense fallback={<Loader />}>
              <ForgotPassword />
            </Suspense>
          }
        />
        <Route
          exact
          path="/reset-password"
          element={
            <Suspense fallback={<Loader />}>
              <ResetPassword />
            </Suspense>
          }
        />
        <Route
          exact
          path="/verification"
          element={
            <Suspense fallback={<Loader />}>
              <Verification />
            </Suspense>
          }
        />

        <Route
          exact
          path="/dashboard"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoutes>
                <DashboardAdmin />
              </ProtectedRoutes>
            </Suspense>
          }
        />
        <Route
          exact
          path="/user-management"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoutes>
                <UserManagament />
              </ProtectedRoutes>
            </Suspense>
          }
        />
        <Route
          exact
          path="/user-management/user-details"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoutes>
                <UserDetails />
              </ProtectedRoutes>
            </Suspense>
          }
        />
        <Route
          exact
          path="/merchant-management"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoutes>
                <MerchantsManagement />
              </ProtectedRoutes>
            </Suspense>
          }
        />
        <Route
          exact
          path="/merchant-management/edit"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoutes>
                <MerchantsEdit />
              </ProtectedRoutes>
            </Suspense>
          }
        />
        <Route
          exact
          path="/merchant-management/details"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoutes>
                <MerchantsDetails />
              </ProtectedRoutes>
            </Suspense>
          }
        />

        <Route
          exact
          path="/merchant-management/business/:id"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoutes>
                <Business />
              </ProtectedRoutes>
            </Suspense>
          }
        />
        <Route
          exact
          path="/subscription-plan-management"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoutes>
                <Subscription />
              </ProtectedRoutes>
            </Suspense>
          }
        />
        <Route
          exact
          path="/subscription-plan-management/view"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoutes>
                <View />
              </ProtectedRoutes>
            </Suspense>
          }
        />
        <Route
          exact
          path="/item-management"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoutes>
                <ItemManagement />
              </ProtectedRoutes>
            </Suspense>
          }
        />
        <Route
          exact
          path="/item-management/edit"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoutes>
                <ItemEdit />
              </ProtectedRoutes>
            </Suspense>
          }
        />
        <Route
          exact
          path="/item-management/variant"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoutes>
                <ViewVariant />
              </ProtectedRoutes>
            </Suspense>
          }
        />
        <Route
          exact
          path="/sub-admin-management"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoutes>
                <SubAdminManagement />
              </ProtectedRoutes>
            </Suspense>
          }
        />
        <Route
          exact
          path="/sub-admin-management/add"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoutes>
                <NewSubAdmin />
              </ProtectedRoutes>
            </Suspense>
          }
        />
        <Route
          exact
          path="/sub-admin-management/edit"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoutes>
                <EditSubadmin />
              </ProtectedRoutes>
            </Suspense>
          }
        />
        <Route
          exact
          path="/sub-admin-management/view"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoutes>
                <ViewSubadmin />
              </ProtectedRoutes>
            </Suspense>
          }
        />
        <Route
          exact
          path="/order-management"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoutes>
                <OrderManagementAdmin />
              </ProtectedRoutes>
            </Suspense>
          }
        />
        <Route
          exact
          path="/order-management/details"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoutes>
                <OrderDetails />
              </ProtectedRoutes>
            </Suspense>
          }
        />
        <Route
          exact
          path="/billing-management"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoutes>
                <BillingManagementAdmin />
              </ProtectedRoutes>
            </Suspense>
          }
        />
        <Route
          exact
          path="/billing-management/details"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoutes>
                <BillingDetailsAdmin />
              </ProtectedRoutes>
            </Suspense>
          }
        />
        <Route
          exact
          path="/promotion-code-management"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoutes>
                <PromotionCodeManagement />
              </ProtectedRoutes>
            </Suspense>
          }
        />
        <Route
          exact
          path="/promotion-code-management/promo-details"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoutes>
                <PromoDetailsAdmin />
              </ProtectedRoutes>
            </Suspense>
          }
        />
        <Route
          exact
          path="/promotion-code-management/add-new-promotion"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoutes>
                <AddNewPromoAdmin />
              </ProtectedRoutes>
            </Suspense>
          }
        />
        <Route
          exact
          path="/category-management"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoutes>
                <CategoryManagementAdmin />
              </ProtectedRoutes>
            </Suspense>
          }
        />
        <Route
          exact
          path="/category-management/view/:id"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoutes>
                <CategoryEdit />
              </ProtectedRoutes>
            </Suspense>
          }
        />
        <Route
          exact
          path="/category-management/subcategory/view/:id"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoutes>
                <EditSubCategory />
              </ProtectedRoutes>
            </Suspense>
          }
        />
        <Route
          exact
          path="/notifications"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoutes>
                <NotificationsAdmin />
              </ProtectedRoutes>
            </Suspense>
          }
        />
        <Route
          exact
          path="/notifications/settings"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoutes>
                <NotificationsSettings />
              </ProtectedRoutes>
            </Suspense>
          }
        />
        <Route
          exact
          path="/notifications/settings-view"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoutes>
                <ViewNotification />
              </ProtectedRoutes>
            </Suspense>
          }
        />
        <Route
          exact
          path="/notifications/push"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoutes>
                <PushNotification />
              </ProtectedRoutes>
            </Suspense>
          }
        />
        <Route
          exact
          path="/notifications/push/details"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoutes>
                <PushNotificationDetails />
              </ProtectedRoutes>
            </Suspense>
          }
        />
        <Route
          exact
          path="/notifications/push/add"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoutes>
                <AddPushNotification />
              </ProtectedRoutes>
            </Suspense>
          }
        />
        <Route
          exact
          path="/transaction-management"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoutes>
                <TransactionManagementAdmin />
              </ProtectedRoutes>
            </Suspense>
          }
        />
        <Route
          exact
          path="/help-support-management"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoutes>
                <HelpandSupportAdmin />
              </ProtectedRoutes>
            </Suspense>
          }
        />
        <Route
          exact
          path="/help-support-management/details"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoutes>
                <HelpDetailsAdmin />
              </ProtectedRoutes>
            </Suspense>
          }
        />
        <Route
          exact
          path="/content-management"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoutes>
                <ContentManagement />
              </ProtectedRoutes>
            </Suspense>
          }
        />
        <Route
          exact
          path="/content-management/edit"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoutes>
                <EditContentAdmin />
              </ProtectedRoutes>
            </Suspense>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
