import React from "react";

const Footer = () => {
  return (
    <>{null}</>
    // <div className="container-fluid z-2">
    //   <footer className="footer">
    //     <div className="row">
    //       <div className="col-md-12 footer-copyright text-center">
    //         <p className="mb-0">Copyright 2024</p>
    //       </div>
    //     </div>
    //   </footer>
    // </div>
  );
};

export default Footer;
