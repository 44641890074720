import adminHttpService from "../adminHttpService";
import Swal from "sweetalert2";

// DASHBOARD
export async function OrderAndProductCount() {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/total-OrderProduct`
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}

export async function Dashboard(formData) {
  try {
    const { data } = await adminHttpService.post(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/dashboards`,
      formData
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function RecentOrdersApi(formData) {
  try {
    const { data } = await adminHttpService.post(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/recent-order`,
      formData
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function OrderDetailsApi(id) {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/orderDetails/${id}`
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function RevenueGraphData() {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/total-order-revenue`
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}

// MERCHANT
export async function AllMerchants(formData, signal) {
  try {
    const { data } = await adminHttpService.patch(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/getAllMerchant`,
      formData,
      signal
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function MerchantCount() {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/total-Merchant`
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}

export async function ChangeMerchantStatus(id, formData) {
  try {
    const { data } = await adminHttpService.put(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/change-merchant-status/${id}`,
      formData
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function DeleteMerchant(id) {
  try {
    const { data } = await adminHttpService.delete(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/delete-merchant/${id}`
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}

export async function ViewMerchant(id) {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/view-merchant/${id}`
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function EditMerchant(id, formData) {
  try {
    const { data } = await adminHttpService.put(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/updateMerchant/${id}`,
      formData
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function MerchantChange(id, formData) {
  try {
    const { data } = await adminHttpService.put(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/merchant-permission/${id}`,
      formData
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function MerchantCommission(id, formData) {
  try {
    const { data } = await adminHttpService.put(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/addPercent/${id}`,
      formData
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function MerchantExports() {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/merchantExprots`
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}

// ITEM MANAGEMENT
export async function GetItems(formData, signal) {
  console.log(formData);
  try {
    const { data } = await adminHttpService.patch(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/getItems`,
      formData,
      signal
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function ViewItem(id) {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/viewItems/${id}`
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function ChangeItemStatus(id, formData) {
  try {
    const { data } = await adminHttpService.put(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/changeItemsStatus/${id}`,
      formData
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function DeleteItem(id) {
  try {
    const { data } = await adminHttpService.delete(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/deleteItems/${id}`
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function EditItems(id, formData) {
  try {
    const { data } = await adminHttpService.put(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/editItems/${id}`,
      formData
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function ItemChange(id, formData) {
  try {
    const { data } = await adminHttpService.put(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/item-permission/${id}`,
      formData
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function ItemExports() {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/exportsItems`
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function GetVarients(id) {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/itemsVarient/${id}`
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function ViewVarients(id) {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/ViewVarient/${id}`
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}

// Category
export async function GetCategory() {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/categoryListing`
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function GetSubCategory() {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/sub-CaetgoryList`
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function GetSection() {
  try {
    const { data } = await adminHttpService.post(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/sectionlist`
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function CategoryExports() {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/categoryAllExports`
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function SubCategoryExports() {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/subCategoryAllExports`
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}

// SUB ADMIN MANG.

export async function GetSubAdmin(formData, signal) {
  try {
    const { data } = await adminHttpService.patch(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/get-subAdmin`,
      formData,
      signal
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}

export async function ChangeSubAdminStatus(id, formData) {
  try {
    const { data } = await adminHttpService.put(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/changeStatus-subAdmin/${id}`,
      formData
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function ViewSubAdmin(id) {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/view-subAdmin/${id}`
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function DeleteSubAdmin(id) {
  try {
    const { data } = await adminHttpService.delete(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/delete-sunAdmin/${id}`
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function AddSubAdmin(formData) {
  try {
    const { data } = await adminHttpService.post(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/add-subAdmin`,
      formData
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function EditSubAdmin(id, formData) {
  try {
    const { data } = await adminHttpService.put(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/update-subAdmin/${id}`,
      formData
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}

// USER MANAG.

export async function GetUsers(formData, signal) {
  try {
    const { data } = await adminHttpService.patch(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/getUserList`,
      formData,
      signal
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}

export async function ChangeUserStatus(id, formData) {
  try {
    const { data } = await adminHttpService.put(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/changeUserStatus/${id}`,
      formData
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}

export async function DeleteUser(id) {
  try {
    const { data } = await adminHttpService.delete(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/userDelete/${id}`
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function ViewUserDetails(id) {
  try {
    const { data } = await adminHttpService.patch(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/getUserDetails/${id}`,
      { search: "" }
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function UserExports() {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/UserExports`
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
// PROMO CODE MANG.

export async function AddPromoCode(formData) {
  try {
    const { data } = await adminHttpService.post(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/add-promo`,
      formData
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function ChangePromoStatus(id, formData) {
  try {
    const { data } = await adminHttpService.put(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/change-promo/${id}`,
      formData
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}

export async function AllPromoCode(formData, signal) {
  try {
    const { data } = await adminHttpService.patch(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/get-promo`,
      formData,
      signal
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function DeletePromoCode(id) {
  try {
    const { data } = await adminHttpService.delete(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/delete-promo/${id}`
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function PromoCodeDetails(id) {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/view-promo/${id}`
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function PromoCodeUpdate(id, formData) {
  try {
    const { data } = await adminHttpService.put(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/edit-promo/${id}`,
      formData
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function PromocodeExports() {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/exportsPromo`
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}

// PUSH NOTIFICATION
export async function AddPushNotificationApi(formData) {
  try {
    const { data } = await adminHttpService.post(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/add-notification`,
      formData
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}

export async function AllPushNotification(formData, signal) {
  try {
    const { data } = await adminHttpService.patch(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/get-notification`,
      formData,
      signal
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function DeletePushNotification(id) {
  try {
    const { data } = await adminHttpService.delete(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/delete-notification/${id}`
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function PushNotificationDetails(id) {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/view-notification/${id}`
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function ResendPushNotification(id) {
  try {
    const { data } = await adminHttpService.post(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/sendagain/${id}`
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}

// NOTIFICATION
export async function AllNotification(formData, signal) {
  try {
    const { data } = await adminHttpService.patch(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/notification`,
      formData,
      signal
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function DeleteNotification(id) {
  try {
    const { data } = await adminHttpService.delete(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/notificationDelete/${id}`
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function NotificationSetting(formData) {
  try {
    const { data } = await adminHttpService.post(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/notifySetting`,
      formData
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}

// CONTENT MANAGEMENT
export async function AllContent(formData, signal) {
  try {
    const { data } = await adminHttpService.post(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/content-list`,
      formData,
      signal
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}

export async function EditContent(id, formData) {
  try {
    const { data } = await adminHttpService.put(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/edit-content/${id}`,
      formData
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function DeleteContent(id) {
  try {
    const { data } = await adminHttpService.delete(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/delete-content/${id}`
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function ContentDetails(id) {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/view-content/${id}`
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}

// ORDERS

export async function AllOrders(formData, signal) {
  try {
    const { data } = await adminHttpService.patch(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/orderList`,
      formData,
      signal
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}

export async function OrderMerchant(formData) {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/orderMerchent `,
      formData
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function OrderCategory(formData) {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/orderCategory  `,
      formData
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function OrderSubCategory(formData) {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/orderSubCategory `,
      formData
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function OrderExports() {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/exportsOrder`
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
// HELP N SUPPORT

export async function SupportList(formData, signal) {
  try {
    const { data } = await adminHttpService.patch(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/get-helplist`,
      formData,
      signal
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function SupportDetails(id) {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/helpDetails/${id} `
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function SupportDelete(id) {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/helpDelete/${id}  `
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function SupportStatus(id, formData) {
  try {
    const { data } = await adminHttpService.put(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/help-changeStatus/${id}`,
      formData
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}

// ATTRIBUTES

export async function GetCategories() {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/getSelectCatergory`
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function GetSubCategories(id) {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/getSelectSubCategory/${id}`
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function AttributesList(formData, signal) {
  try {
    const { data } = await adminHttpService.patch(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/getAttributes`,
      formData,
      signal
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function AddAttribute(formData) {
  try {
    const { data } = await adminHttpService.post(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/addAttribute`,
      formData
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function UpdateAttribute(id, formData) {
  try {
    const { data } = await adminHttpService.put(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/update-attributes/${id}`,
      formData
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function AttributeDelete(id) {
  try {
    const { data } = await adminHttpService.delete(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/deleteAttributes/${id}  `
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function AttributeStatus(id, formData) {
  try {
    const { data } = await adminHttpService.put(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/changeAttributes/${id}`,
      formData
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}

// VALUES

export async function ValuesList(formData, signal) {
  try {
    const { data } = await adminHttpService.patch(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/getValues`,
      formData,
      signal
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function AddValues(formData) {
  try {
    const { data } = await adminHttpService.post(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/addValues`,
      formData
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function UpdateValues(id, formData) {
  try {
    const { data } = await adminHttpService.put(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/updateValues/${id}`,
      formData
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function SelectedAttributes(id) {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/getSelectAttributes/${id}`
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function ValuesDelete(id) {
  try {
    const { data } = await adminHttpService.delete(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/deleteValues/${id}  `
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function ValueStatus(id, formData) {
  try {
    const { data } = await adminHttpService.put(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/changeValues/${id}`,
      formData
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
// Section

export async function AddSection(formData) {
  try {
    const { data } = await adminHttpService.post(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/addSection`,
      formData
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
// Subscription

export async function SubscriptionList(formData, signal) {
  try {
    const { data } = await adminHttpService.patch(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/getSubcriptionPlan`,
      formData,
      signal
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function SubscriptionView(id) {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/viewSubcription/${id}`
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}

// TRANSACTION

export async function TransactionList(formData, signal) {
  try {
    const { data } = await adminHttpService.patch(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/transactionList`,
      formData,
      signal
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function ExportTransaction(formData) {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/exports-transaction`,
      formData
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function DownloadTransactionBill(id) {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/transactionInvoice/${id}`
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
// BILLING
export async function BillingList(formData, signal) {
  try {
    const { data } = await adminHttpService.patch(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/billingList`,
      formData,
      signal
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function ExportBilling(formData) {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/exports-billing`,
      formData
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
export async function DownloadBill(id) {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/downloadInvoice/${id}`
    );
    if (data && !data?.error) {
      return { data };
    }
  } catch (error) {
    if (error.response) {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }
    return { error };
  }
}
