import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import Swal from "sweetalert2";
import { navState } from "../Recoil/handleSidebar";
import { userDataState } from "../Recoil/userData";
import SecureLS from "secure-ls";
import { sidebarMenuOptions } from "../utils/sidebarMenuOptions";

const SidebarAdmin = ({ slide }) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [activeBar, setActiveBar] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const navCrrState = useRecoilValue(navState);
  const setNavState = useSetRecoilState(navState);
  const getUserProfileDataState = useRecoilValue(userDataState);

  let ls = new SecureLS();
  let userRoles = ls.get("bout_roles");

  const handleLogout = async () => {
    localStorage.removeItem("boutique-token-admin");
    localStorage.removeItem("boutique_admin_id");
    localStorage.removeItem("recoil-persist");
    Swal.fire({
      toast: true,
      icon: "success",
      position: "top-end",
      title: "Logout successfully",
      showConfirmButton: false,
      timerProgressBar: true,
      timer: 3000,
    });
    navigate("/login");
  };

  useEffect(() => {
    setActiveBar(slide);
  }, []);

  return (
    <div
      className={`sidebar sidebar-wrapper overflow-x-hidden d-block ${
        navCrrState ? "close_icon" : ""
      }`}
      style={{ height: "100vh", overflowY: "auto" }}
    >
      <div>
        <div
          className="logo logo-wrapper overflow-x-hidden pb-0 mb-0 sidebar_icon "
          onClick={() => setNavState(!navCrrState)}
        >
          <div
            className={`${!navCrrState ? "d-inline-block w-100" : "d-none"}`}
          >
            <Link to={"/dashboard"} className="d-inline">
              {getUserProfileDataState?.type === "admin"
                ? "Boutique Admin"
                : "Boutique Sub Admin"}
            </Link>
            <i className="ri-apps-line status_toggle middle sidebar-toggle float-end" />
          </div>
        </div>
        {navCrrState && (
          <div
            className="sidebar_icon mb-0 pb-0 d-flex justify-content-center ms-4 ps-1 fs-5"
            onClick={() => setNavState(!navCrrState)}
          >
            <i class="fa fa-angle-left"></i>
          </div>
        )}
        <nav className="sidebar-main">
          <div id="sidebar-menu" className="menu">
            <ul
              className="sidebar-links overflow_x_hidden scroll_mobile"
              id="simple-bar"
            >
              {sidebarMenuOptions
                ?.filter((option) => userRoles?.includes(option?.role))
                ?.map((option, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      setActiveBar(option?.name);
                      // setNavState(!navCrrState);
                    }}
                    className={`list-sidebar ${
                      activeBar === option?.name ? "active_nav" : ""
                    }`}
                  >
                    <Link
                      className={`sidebar-link sidebar-title link-nav d-inline-block w-100`}
                      to={option?.link}
                    >
                      <i
                        className={` ${option?.icon} ${
                          navCrrState
                            ? "d-flex justify-content-between ms-3 "
                            : ""
                        }`}
                      />
                      <span className={` ${navCrrState ? "d-none" : ""}`}>
                        {option?.name}
                      </span>
                    </Link>
                    {/* Render submenu if present */}
                    {/* {option.submenu && (
                      <ul className="submenu-sidebar">
                        {option.submenu.map((subOption, subIndex) => (
                          <li key={subIndex}>
                            <Link to={subOption.link}>{subOption.name}</Link>
                          </li>
                        ))}
                      </ul>
                    )} */}
                  </li>
                ))}
              {/* Add logout button */}
              <li onClick={handleLogout} className="list-sidebar ">
                <Link className="sidebar-link sidebar-title link-nav d-inline-block w-100">
                  <i
                    className={` fas fa-sign-out-alt ${
                      navCrrState ? " ms-3 " : ""
                    }`}
                  />
                  <span className={` ${navCrrState ? "d-none" : ""}`}>
                    Logout
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default React.memo(SidebarAdmin);
