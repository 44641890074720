import React, { useEffect, useState } from "react";
import Footer from "../Footer";
import Header from "../Header";
import SidebarAdmin from "../SidebarAdmin";
import { useLocation } from "react-router-dom";
import moment from "moment";

const View = () => {
  const { state } = useLocation();

  return (
    <div>
      <div>
        <div className="tap-top">
          <span className="lnr lnr-chevron-up" />
        </div>
        {/* tap on tap end */}
        {/* page-wrapper Start*/}
        <div className="page-wrapper compact-wrapper" id="pageWrapper">
          <Header />
          {/* Page Body Start*/}
          <div className="page-body-wrapper">
            {/* Page Sidebar Start*/}
            <SidebarAdmin slide={"Subscription Management"} />
            {/* Page Sidebar Ends*/}
            {/* index body start */}
            <div className="page-body">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="title-header option-title">
                          <h5>Subscription Plan Details</h5>
                        </div>
                        <form className="theme-form theme-form-2 mega-form">
                          <div className="row">
                            {/* <div className="col-md-4 mb-4">
                              <label className="form-label-title mb-2">
                                Sub-Admin ID
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                defaultValue="SA001"
                              />
                            </div> */}
                            <div className="col-md-6 mb-4">
                              <label className="form-label-title mb-2">
                                Merchant Name
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                defaultValue={state?.merchantId?.name}
                                disabled
                              />
                            </div>
                            <div className="col-md-6 mb-4">
                              <label className="form-label-title mb-2">
                                Plan Type
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                defaultValue={state?.planType}
                                disabled
                              />
                            </div>
                            <div className="col-md-6 mb-4">
                              <label className="form-label-title mb-2">
                                Date
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                value={moment(state?.validity).format(
                                  "MMM Do YYYY : HH:mm A"
                                )}
                                disabled
                              />
                            </div>
                            <div className="col-md-6 mb-4">
                              <label className="form-label-title mb-2">
                                Plan Cost
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                defaultValue={state?.cost}
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* footer start*/}
            <Footer />
            {/* footer End*/}
          </div>
          {/* index body end */}
        </div>
        {/* Page Body End */}
      </div>
      {/* page-wrapper End*/}
      {/* Modal Start */}
    </div>
  );
};

export default View;
