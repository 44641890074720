export const sidebarMenuOptions = [
  {
    name: "Dashboard",
    role: "dashboard",
    link: "/dashboard",
    icon: "fas fa-home",
  },
  {
    name: "Users Management",
    role: "user",
    link: "/user-management",
    icon: "fas fa-user",
  },
  {
    name: "Merchants Management",
    role: "merchants",
    link: "/merchant-management",
    icon: "fas fa-store",
  },
  {
    name: "Item Management",
    role: "item",
    link: "/item-management",
    icon: "fas fa-cart-arrow-down",
  },
  {
    name: "Sub Admin Management",
    role: "sub admin",
    link: "/sub-admin-management",
    icon: "fas fa-users",
  },
  {
    name: "Orders Management",
    role: "order",
    link: "/order-management",
    icon: "fas fa-box-open",
  },
  {
    name: "Billing Management",
    role: "billing",
    link: "/billing-management",
    icon: "fas fa-file-invoice",
  },
  {
    name: "Promotion Code Management",
    role: "promotion",
    link: "/promotion-code-management",
    icon: "fas fa-tag",
  },
  {
    name: "Subscription Management",
    role: "subscription",
    link: "/subscription-plan-management",
    icon: "fas fa-shopping-basket",
  },
  {
    name: "Category Management",
    role: "category",
    link: "/category-management",
    icon: "fas fa-th-list",
  },
  {
    name: "Notifications",
    role: "notification",
    link: "/notifications",
    icon: "fas fa-bell",
  },
  {
    name: "Push Notifications",
    link: "/notifications/push",
    role: "notification",
    icon: "fas fa-bullhorn",
  },
  {
    name: "Transaction Management",
    role: "transaction",
    link: "/transaction-management",
    icon: "fas fa-sync",
  },
  {
    name: "Content Management",
    role: "content",
    link: "/content-management",
    icon: "fas fa-edit",
  },
  {
    name: "Help & Support Management",
    role: "help",
    link: "/help-support-management",
    icon: "fas fa-headset",
  },
];
